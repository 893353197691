import Player from "@vimeo/player";
import {ComponentLoader} from "ui-base/src/AbstractComponent";
import {Modal} from "ui-base/src/Components/Modal/_resources/ts/Modal.c"

declare const $: any;

export class VideoModal extends Modal {
    public static selector: string = 'video-modal';
    private player: any = Player;

    public init(){
        super.init();
        this.player = new Player(this.getComponentElement().find('iframe'));
    }

    public closeModal() {
        super.closeModal();
        this.player.pause();
    }

}

// Setup the component loader
new ComponentLoader(VideoModal);
