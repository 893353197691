import {BreakpointsValues} from "ui-base/src/Enum/ts/BreakpointsValues";
import {Breakpoints} from "ui-base/src/Enum/ts/Breakpoints";

declare let $: any;

export class BreakPointModule {

    protected _screenWidth: number;

    public constructor() {
        if (!this._screenWidth) this.updateCurrentWidth();

        $(window).on('resize', this.updateCurrentWidth.bind(this));
    }

    private updateCurrentWidth() {
        this._screenWidth = window.innerWidth;
    }

    public isSmallDown() {
        return this._screenWidth < BreakpointsValues.MEDIUM;
    }

    public isSmall() {
        return this._screenWidth >= BreakpointsValues.SMALL && this._screenWidth < BreakpointsValues.MEDIUM;
    }

    public isSmallUp() {
        return this._screenWidth >= BreakpointsValues.SMALL;
    }

    public isMediumDown() {
        return this._screenWidth < BreakpointsValues.LARGE;
    }

    public isMedium() {
        return this._screenWidth >= BreakpointsValues.MEDIUM && this._screenWidth < BreakpointsValues.LARGE;
    }

    public isMediumUp() {
        return this._screenWidth >= BreakpointsValues.MEDIUM;
    }

    public isLargeDown() {
        return this._screenWidth < BreakpointsValues.XLARGE;
    }

    public isLarge() {
        return this._screenWidth >= BreakpointsValues.LARGE && this._screenWidth < BreakpointsValues.XLARGE;
    }

    public isLargeUp() {
        return this._screenWidth >= BreakpointsValues.LARGE;
    }

    public isXLarge() {
        return this._screenWidth >= BreakpointsValues.XLARGE && this._screenWidth < BreakpointsValues.XXLARGE;
    }

    public isXXLarge() {
        return this._screenWidth >= BreakpointsValues.XXLARGE;
    }

    public getCurrentBreakpoint() {
        if (this.isSmallDown()) return Breakpoints.SMALL;
        if (this.isMedium()) return Breakpoints.MEDIUM;
        if (this.isLargeUp()) return Breakpoints.LARGE;
    }
}
