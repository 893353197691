import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class Modal extends AbstractComponent {
    public static selector: string = 'modal';
    public page: string = 'data-modal-page';
    public trigger: string = 'data-modal-page-trigger';
    public close: string = 'modal-close';
    public back: string = 'modal-back';
    public next: string = 'modal-next';
    public defaultActive: string = 'data-default-active-modal-page';
    public addInterval: string = 'data-add-interval';
    private _nextDisplayTime: number = 0;
    private _interval: number = 60000;
    private _persistPopCount: boolean = false;
    private _popCount: number = 0;
    private _exitPop: boolean = false;
    private _loggedInRedirect: string = 'logged-in-redirect';

    public init() {
        this.openModalFromHash();
        this.triggerModalClickEventListener();
        this.resizeWindowEventListener();
        this.setupClickHandlers();

        if (typeof this.getComponentElement().attr("modal-exit-limit") !== "undefined") {
            this.listenForScreenExit();
            this._exitPop = true;

            let persistPopCount = this.getComponentElement().attr("persist-pop-count");
            if (typeof persistPopCount !== "undefined") {
                this._persistPopCount = Boolean(persistPopCount)
            }
        }

        if(this.getComponentElement().attr(this.addInterval) == true) {
            this._nextDisplayTime = Date.now()
        }
    }

    protected resizeWindowEventListener() {
        $(window).on('resize', function () {
            this.moveModalToCenter();
        }.bind(this));

        return this;
    }

    protected triggerModalClickEventListener() {
        $(`[data-trigger-modal='${this.getModalName()}']`).on('click', function (e) {
            if ((e.currentTarget.tagName !== 'A') && e.currentTarget.outerHTML.indexOf('href')!==-1){
                e.preventDefault();
            }
            this.openModal();
        }.bind(this));
        return this;
    }

    protected isModalOpen() {
        return this.getComponentElement().hasClass("modal--open");
    }

    protected getModalName() {
        return this.getComponentElement().attr("modal-name");
    }

    public openModalFromHash() {
        let modalName = this.getComponentElement().attr("modal-name");

        this.getDI().getUrlModule().onHashChange((hash) => {
            if (hash == modalName && this.isWaitTimeOver()) {
                if (this._exitPop && this.shouldShowExitPop()) {
                    this.incrementPopCount();
                }
                this.openModal();
            }

            if ($('[' + this.trigger + '=' + hash + ']', this.getComponentElement()).length) {
                this.showPageByKey(hash);
            }
        })
    }

    public openModal() {
        if(this.getLoggedInRedirect())
        {
            window.location = this.getLoggedInRedirect();
            return;
        }
        this.getComponentElement().removeClass('modal--close');
        this.getComponentElement().addClass('modal--open');
        if (this.isScrollable()) {
            $('body').css('overflow', 'hidden');
        }
        this.moveModalToCenter();
    }

    public moveModalToCenter() {
        let viewHeight = $(window).outerHeight();
        let modalHeight = $('[modal-content]', this.getComponentElement()).outerHeight();
        let scrollTop = $(window).scrollTop();
        let modalTop = (scrollTop + (viewHeight / 2) - (modalHeight / 2));

        $('[modal-content]', this.getComponentElement()).css('top', `${modalTop}px`);
    }

    public closeModal() {
        let unclosableAttr = this.getComponentElement().attr("modal-unclosable");
        if (!unclosableAttr){
            this.getComponentElement().removeClass("modal--open")
            this.getComponentElement().addClass('modal--close');
        }
        $('body').css('overflow', '');
    }

    protected incrementNextDisplayTime()
    {
        this._nextDisplayTime =  Date.now() + this._interval
    }

    protected isWaitTimeOver()
    {
        let intervalAttributeAdded = this.getComponentElement().attr(this.addInterval) == true

        if (intervalAttributeAdded)
        {
            return  this._nextDisplayTime > 0 && this._nextDisplayTime < Date.now();
        }

        return true;
    }

    public setupClickHandlers() {

        // Page Trigger
        $('[' + this.trigger + ']', this.getComponentElement()).on('click', (e) => {

            let current = $(e.currentTarget);
            let pageToShowValue = current.attr(this.trigger);
            this.showPageByKey(pageToShowValue);
        });

        // Modal Close
        $('[' + this.close + ']', this.getComponentElement()).on('click', () => {
            let defaultActivePageKey = this.getComponentElement().attr(this.defaultActive);
            this.closeModal();
            this.incrementNextDisplayTime()
            this.showPageByKey(defaultActivePageKey);
        });

        // Modal Back Button
        $('[' + this.back + ']', this.getComponentElement()).on('click', (e) => {
            let current = $(e.currentTarget);
            let backKey = current.attr(this.back);
            this.showPageByKey(backKey);
        });

        // Modal Back Button
        $('[' + this.next + ']', this.getComponentElement()).on('click', (e) => {
            let current = $(e.currentTarget);
            let nextKey = current.attr(this.next);
            this.showPageByKey(nextKey);
        });
    }

    public hideAllModalPages() {
        let modalPage = $('[' + this.page + ']', this.getComponentElement());
        modalPage.addClass('hide');
    }

    public showPageByKey(key) {
        this.hideAllModalPages();
        let pageToShow = $('[' + this.page + '="' + key + '"]');
        pageToShow.removeClass('hide');
    }

    public shouldShowExitPop() {
        let exitLimit = this.getComponentElement().attr('modal-exit-limit');
        return (
            this.isModalOpen() === false
            && (exitLimit === "0" || this.getPopCount() < parseInt(exitLimit))
        );
    }

    protected getPopCount(): number {
        if (this._persistPopCount) {
            let cookieValue = this.getCookie(this.getModalName() + '-exit-popped');

            if (cookieValue === undefined) {
                this.setPopCount(0);
            } else {
                this.setPopCount(parseInt(cookieValue));
            }
        }

        return this._popCount;
    }

    protected setPopCount(value: number): this {
        if (this._persistPopCount) this.setCookie(this.getModalName() + '-exit-popped', value);

        this._popCount = value;
        return this;
    }

    protected incrementPopCount(): this {
        this.setPopCount(this.getPopCount() + 1);
        return this;
    }

    protected getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    protected setCookie(name, value) {
        document.cookie = `${name} = ${value}; ${20 * 365}; path=/`;
    }

    public listenForScreenExit() {
        $(document).bind("mouseleave", function (e) {
            if (e.pageY - $(window).scrollTop() <= 1 && this.shouldShowExitPop() && this.isWaitTimeOver()) {
                this.moveModalToCenter();
                this.incrementPopCount();

                if (this.getComponentElement().attr('exit-modal-animation')) {
                    this.getComponentElement().addClass('modal--' + this.getComponentElement().attr('exit-modal-animation'));
                }

                this.openModal();
            }
        }.bind(this));
    }

    private isScrollable() {
        let element = this.getComponentElement();

        if (element.attr(`scrollable-auto`) !== undefined) {
            element.removeAttr('scrollable');

            if (element.find('.modal__content').height()+150 > $(window).height()) {
                element.attr('scrollable', 'true');
                return true;
            }
        }

        return false;
    }

    private getLoggedInRedirect()
    {
        return this.getComponentElement().attr(this._loggedInRedirect);
    }

}

new ComponentLoader(Modal);
